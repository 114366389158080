<template>
  <div class="paramListBox fadeIn">
    <div class="title">
       <van-nav-bar
  :title="$route.query.pjName"
  left-text="返回"
  left-arrow
  @click-left="$router.go(-1)"
/>
    </div>
  
    <div v-for="(item, index) in paramList" :key="index + keysIndex" class="itemParam">
      <div style="width:30%" class="allTitle">
        {{ item.name }}
      </div>
      <div style="width:70%">
        <div
          v-for="(element,indexs) in item.children"
          :key="indexs + keysIndex"
          class="elementParam"
        >
          <div >{{ element.name }}</div>
          <div class="param" v-if="!element.lamp">
            {{ paramValObj[element.paramName] }} {{ element.company }}
          </div>
          <div class="param" v-else>
            <img
              v-if="paramValObj[element.paramName] == 0"
              style="height: 90%; margin: 2.5%"
              src="@/assets/imgproject/warning.jpg"
              alt=""
            />
            <img
              v-else
              style="height: 90%; margin: 2.5%"
              src="@/assets/imgproject/success.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let that;
import { powerParam } from "../util/constant";
export default {
  data() {
    return {
      paramList: powerParam,
      paramFrom: {
        projectId: "",
        paramTags: [],
      },
      paramValObj: {},
      keysIndex: "%1",
    };
  },
  methods: {
    getParamInfo() {
      that.$api.energy.getParams(that.paramFrom).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            that.paramValObj[item.paramTag] = item.value;
            that.keysIndex = that.keysIndex + "%1";
          });
        }
      });
    },
  },
  mounted() {
    that = this;
    let arr = [];
    that.paramList.forEach((item) => {
        item.children.forEach(element=>{
            arr.push(element.paramName);
        })
      
    });
    that.paramFrom = {
      projectId: that.$route.query.projectId,
      paramTags: arr,
    };
    that.getParamInfo();
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.paramListBox {
  width: 100%;
  height: 100%;
  padding:0 10px;
  overflow-y:scroll ;
}
.itemParam{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    background: rgb(43,194,209);
   border-radius: 15px;
   margin: 10px 0;
   color: #fff;
  
}
.allTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    font-size: 20px;
}
.elementParam{
    width: 100%;
    height: 40px;
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid #fff;
}
.elementParam div{
    width: 50%;
    height: 100%;
    line-height: 40px;
    font-size: 16px;
}
.param{
  overflow: hidden;
}
.title{
  position: sticky;
  top: 0;
  left: 0;
}
</style>